/* eslint-disable camelcase */
import http from "utility/fetch";

export const getOtp = (data) => http.post("/designer/login-otp", data);

export const verifyOtp = (data) => http.post("/designer/verify-otp", data);

export const getAllUserTypes = (data) => http.post("auth/getAllRole", data);

export const createUserType = (data) => http.post("auth/createRole", data);

export const createPermissionTemplate = (data) =>
  http.post("auth/createPermissionTemplate", data);

export const getPermissionTemplate = (data) =>
  http.post("auth/getPermissionTemplate", data);

export const getPermissionByUserType = (data) =>
  http.post("/auth/getRoleBasedPermissionById", data);

export const getPermissionByUserId = (data) =>
  http.post("/auth/getUserBasedPermissions", data);

export const updatePermissionByUserType = (data) =>
  http.post("/auth/updateRolePermissionForAuth", data);

export const updatePermissionByUserId = (data) =>
  http.post("auth/updateSpecialPermissions", data);

export const exportAllUserPermissions = (data) =>
  http.post("/admin/getAllUserWithAuthorizationPermissionsExportRequest", data);
